<template>
  <div>
    <h1>{{ title }}</h1>
    <small class="block mb-4">Gesorteerd op aanmaak datum, nieuwste als eerste</small>
    <div class="flex flex-col lg:flex-row gap-4">
      <UISelectFixed
        v-if="!fixedType"
        v-model="type"
        type="BOEKING_TYPE"
        class="w-full max-w-sm"
      />
      <UISelect
        v-model="apiData.facturatie"
        label="Facturatie"
        :options="[
          { label: 'Alles tonen', value: null },
          { label: 'Zonder factuur', value: false },
          { label: 'Op factuur', value: true },
        ]"
      />
      <UISelect
        v-model="apiData.days"
        label="Geboekt op"
        :options="[
          { label: 'Alles tonen', value: null },
          { label: 'Vandaag geboekt', value: 0 },
          { label: 'Sinds gisteren geboekt', value: 1 },
          { label: 'Sinds eergisteren geboekt', value: 2 },
          { label: 'Laatste 3 dagen geboekt', value: 3 },
          { label: 'Laatste 7 dagen geboekt', value: 7 },
          { label: 'Laatste 14 dagen geboekt', value: 14 },
          { label: 'Laatste 30 dagen geboekt', value: 30 },
          { label: 'Laatste 60 dagen geboekt', value: 60 },
          { label: 'Laatste 90 dagen geboekt', value: 90 },
          { label: 'Laatste 120 dagen geboekt', value: 120 },
        ]"
      />
      <button v-if="apiData.days !== 0" class="btn small self-end text-xs bg-dashboard-dark text-white" @click="apiData.days = 0">
        Vandaag
      </button>
    </div>
    <UITableRitten
      v-bind="$attrs"
      :type="apiData.type"
      :uit_onbevestigden="$attrs.uit_onbevestigden || type === 'ONBEVESTIGD'"
      :list="loading || !data ? [] : data.list"
      class="mt-4"
      @refresh="getData"
    />
    <UITablePagination
      v-if="data"
      v-model:limit="apiData.limit"
      v-model:offset="apiData.offset"
      class="mt-2"
      :count="data.count"
    />
    <!-- <pre v-text="loading"></pre>
    <pre v-text="data"></pre> -->
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import clone from 'just-clone'

import UITableRitten from '@/components/Table/Ritten/Index.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import UISelect from '@/components/UI/Select/Select.vue'

import useGetApi from '@/hooks/useGetApi'
import useRouteQueryString from '@/hooks/useRouteQueryString'

const props = defineProps({
  fixedType: String,
  fixedData: {
    type: Object,
    default() {
      return {}
    },
  },
  title: {
    type: String,
    default: 'Boekingen',
  },
})

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const type = props.fixedType ? ref(props.fixedType) : useRouteQueryString('type')
const getType = () => String(props.fixedType || type.value || 'ALLES').toUpperCase()

type.value = getType()

const apiData = ref({
  type: type.value,
  facturatie: null,
  days: null,
  limit: 20,
  offset: 0,
})

watch(() => type.value, () => {
  apiData.value.type = getType()
})

const apiDataMerged = computed(() => Object.assign(clone(apiData.value), props.fixedData))
const { data, getData, /* reset, cancel, */ loading } = useGetApi('/api/dashboard/ritten/list', apiDataMerged, { watch: true })
</script>
